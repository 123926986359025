body {
  margin: 0;
  background-image: url(../../static/media/background.ec4cda91.png);
  background-size: cover;
  background-color: hotpink;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.new-meme {
  background-color: white;
}
#about-sub {
  color: white;
  border: 2px solid red;
  position: fixed;
  font-size: 14pt;
  text-align: left;
  width: 500px;
  height: 100%;
  background: black;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.responsive {
  width: 50%;
  height: 50%;
  background-color: transparent !important;
}

.card {
  border: none !important;
}

.card-head {
  background-color: hotpink !important;
  color: black;
}

.card-body {
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  background-color: rgba(255, 255, 255, 0.3);
}

.spicymeme {
  color: pink;
  font-weight: 900;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

@media only screen and (max-width: 900px) {
  #about-sub {
    display: none !important;
  }
  .desktopNavbar {
    display: none !important;
  }
  .responsive {
    width: 100%;
    height: 100%;
  }
}

.App-header {
  /* background-image: url('./cyberpunk-wallpaper-hd.jpg'); */
  background-size: cover;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: #61dafb;
}

.navbar-brand > a {
  color: #cbdfbd !important;
}

.bottomText {
  background-color: hotpink;
  color: black;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

